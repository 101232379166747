import {ApiBase} from '@jetCommon/jpe-api/base.js';

export default class EmploymentVariationApiCommon extends ApiBase {
    static resourceName = 'employment_variations';

    getHireKindChoices() {
        return this.apiGet('hire_kind_choices/');
    }
    getWorkTimePatternChoices() {
        return this.apiGet('work_time_pattern_choices/');
    }
    getWorkModeChoices() {
        return this.apiGet('work_mode_choices/');
    }
    getCompanySiteChoices(id) {
        return this.apiGet(`${id}/company_site_choices/`);
    }
    getInpsQualificationChoices() {
        return this.apiGet('inps_qualification_choices/');
    }
    getIstatQualificationChoices() {
        return this.apiGet('istat_qualification_choices/');
    }
    getTfrDestinationChoices() {
        return this.apiGet('tfr_destination_choices/');
    }
    getInstallmentsConfigChoices() {
        return this.apiGet('installments_config_choices/');
    }
    getCCNLLevelChoices(params) {
        return this.apiGet('ccnl_level_choices/', {params});
    }
    getCCNLQualificationChoices() {
        return this.apiGet('ccnl_qualification_choices/');
    }
    getCustomSalaryItemsChoices(params) {
        return this.apiGet('custom_salary_items_choices/', {params});
    }
    getINPSContributoryPositionChoices(id) {
        return this.apiGet(`${id}/inps_contributory_position_choices/`);
    }
    getINAILPoliciesChoices(id) {
        return this.apiGet(`${id}/inail_policies_choices/`);
    }
    getDepositFrequencyChoices() {
        return this.apiGet('deposit_frequency_choices/');
    }
    getUnionPaymentModeChoices() {
        return this.apiGet('union_payment_mode_choices/');
    }
    recordChanges(data) {
        return this.apiPost('record_changes/', data);
    }
}
