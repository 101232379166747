import {ApiBase} from '@jetCommon/jpe-api/base.js';

export default class INAILPolicyApiCommon extends ApiBase {
    static resourceName = 'inail_policies';

    getRegulatoryFrameworkChoices() {
        return this.apiGet('regulatory_framework_choices/');
    }

    createCompletePolicy(data) {
        return this.apiPost('create_complete_policy/', data);
    }

    updateCompletePolicy(id, data) {
        return this.apiPost(`${id}/update_complete_policy/`, data);
    }

    deleteCompletePolicy(id) {
        return this.apiPost(`${id}/delete_complete_policy/`);
    }
}
