import {ApiBase} from '@jetCommon/jpe-api/base.js';

export default class DesignationApiCommon extends ApiBase {
    static resourceName = 'designations';

    createCompleteDesignation(data) {
        return this.apiPost('create_complete_designation/', data);
    }

    updateCompleteDesignation(id, data) {
        return this.apiPost(`${id}/update_complete_designation/`, data);
    }

    deleteCompleteDesignation(id) {
        return this.apiPost(`${id}/delete_complete_designation/`);
    }

    getRoleChoices(excludeLegal = false) {
        return this.apiGet('role_choices/', {params: {exclude_legal: excludeLegal}});
    }
}
