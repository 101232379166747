import {DataStoreApiBase} from '@jetCommon/data-stores-api/base.js';

export default class INPSTablesApiCommon extends DataStoreApiBase {
    static resourceName = 'inps_tables';

    getINPSTableChoices() {
        return this.apiGet('inps_tables_choices/');
    }

    getApprenticeshipINPSTableChoices() {
        return this.apiGet('apprenticeship_inps_tables_choices/');
    }
}
