import {end} from '@jetCommon/api.js';
import AtecoApiCommon from '@jetCommon/data-stores-api/ateco.js';
import AuthApi from '@/api/auth.js';
import CCNLApiCommon from '@jetCommon/data-stores-api/ccnl.js';
import CompanyApi from '@/api/company.js';
import CompanyBilateralInstitutionConfigApiCommon from '@/api/company_bilateral_institution_config.js';
import CompanyCCNLSettingsApiCommon from '@/api/company_ccnl_settings.js';
import CompanyDetailsApiCommon from '@/api/company_details.js';
import CompanyHealthFundConfigApiCommon from '@/api/company_health_fund_config.js';
import CompanyINAILSettingsApiCommon from '@/api/company_inail_settings.js';
import CompanyINPSSettingsApiCommon from '@/api/company_inps_settings.js';
import CompanySiteAddressApiCommon from '@/api/company_site_address.js';
import CompanySiteApiCommon from '@/api/company_site.js';
import CountriesApiCommon from '@jetCommon/data-stores-api/countries.js';
import DataStoreINAILRiskItemApiCommon from '@/api/ds_inail_risk_item.js';
import DesignationAddressApiCommon from '@/api/designation_address.js';
import DesignationApiCommon from '@/api/designation.js';
import DesignationDocumentApiCommon from '@/api/designation_document.js';
import EmploymentVariationsApi from '@/api/employment-variations.js';
import EmploymentsApi from '@/api/employments.js';
import INAILOfficesApiCommon from '@jetCommon/data-stores-api/inail_offices.js';
import INAILPolicyApiCommon from '@/api/inail_policy.js';
import INAILRiskItemApiCommon from '@/api/inail_risk_item.js';
import INPSAuthCodesApiCommon from '@jetCommon/data-stores-api/inps_auth_codes.js';
import INPSContributoryPositionApiCommon from '@/api/inps_contributory_position.js';
import INPSOfficesApiCommon from '@jetCommon/data-stores-api/inps_offices.js';
import INPSTablesApiCommon from '@jetCommon/data-stores-api/inps_tables.js';
import MunicipalitiesApiCommon from '@jetCommon/data-stores-api/municipalities.js';
import PersonApiCommon from '@jetCommon/api/person.js';
import ProvincesApiCommon from '@jetCommon/data-stores-api/provinces.js';
import UploadedFilesApiCommon from '@/api/uploaded_files.js';
import WelfareFundsApiCommon from '@jetCommon/data-stores-api/welfare_funds.js';
import WorkerApi from '@/api/worker.js';
import WorkerHomeAddressApiCommon from '@/api/worker_home_address.js';
import WorkerResidentialAddressApiCommon from '@/api/worker_residential_address.js';
import WorkerVariationsApi from '@/api/worker-variations.js';

export const api = {
    end,
    ateco: new AtecoApiCommon(),
    auth: new AuthApi(),
    ccnl: new CCNLApiCommon(),
    companies: new CompanyApi(),
    companyBilateralInstitutionConfigs: new CompanyBilateralInstitutionConfigApiCommon(),
    companyCCNLSettings: new CompanyCCNLSettingsApiCommon(),
    companyDetails: new CompanyDetailsApiCommon(),
    companyHealthFundConfigs: new CompanyHealthFundConfigApiCommon(),
    companyINPSSettings: new CompanyINPSSettingsApiCommon(),
    companyINAILSettings: new CompanyINAILSettingsApiCommon(),
    companySiteAddresses: new CompanySiteAddressApiCommon(),
    companySites: new CompanySiteApiCommon(),
    countries: new CountriesApiCommon(),
    datastoreINAILRiskItems: new DataStoreINAILRiskItemApiCommon(),
    designationAddresses: new DesignationAddressApiCommon(),
    designationDocuments: new DesignationDocumentApiCommon(),
    designations: new DesignationApiCommon(),
    employmentVariations: new EmploymentVariationsApi(),
    employments: new EmploymentsApi(),
    inailPolicies: new INAILPolicyApiCommon(),
    inailOffices: new INAILOfficesApiCommon(),
    inailRiskItems: new INAILRiskItemApiCommon(),
    inpsAuthCodes: new INPSAuthCodesApiCommon(),
    inpsContributoryPositions: new INPSContributoryPositionApiCommon(),
    inpsOffices: new INPSOfficesApiCommon(),
    inpsTables: new INPSTablesApiCommon(),
    municipalities: new MunicipalitiesApiCommon(),
    persons: new PersonApiCommon(),
    provinces: new ProvincesApiCommon(),
    uploadedFiles: new UploadedFilesApiCommon(),
    welfareFunds: new WelfareFundsApiCommon(),
    workerHomeAddresses: new WorkerHomeAddressApiCommon(),
    workerResidentialAddresses: new WorkerResidentialAddressApiCommon(),
    workerVariations: new WorkerVariationsApi(),
    workers: new WorkerApi(),
};
